
              @import "@/assets/css/variables/variables.scss";
            

img {
  height: 50px;
}

p {
  margin-top: 20px;
  color: $primary-green;
}
