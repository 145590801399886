@import './theme';
@import '~@/assets/css/_rtl-sass.scss';
@import '~@/assets/css/animation.scss';

$url: '~@/assets/images/at/';

//login
$logo: $url + 'logo.png';
$logo2: $url + 'logo2.png';
$logo3: $url + 'logo3.png';
$login_lang_down: '~@/assets/images/header/login_lang_down.png';
$login_lang_down2: '~@/assets/images/header/login_lang_down2.png';
$loading: $url + 'loading.png';

$box-shadow: 0 -9px 35px 0 rgba(0, 0, 0, 0.13);

$DEVICE_SIZE_MOBILES: '320px';
$DEVICE_SIZE_MOBILEM: '375px';
$DEVICE_SIZE_MOBILEL: '425px';
$DEVICE_SIZE_TABLET: '768px';
$DEVICE_SIZE_LAPTOP: '1024px';
$DEVICE_SIZE_LAPTOPL: '1440px';
$DEVICE_SIZE_DESKTOP: '2560px';
