.success {
  padding: 40px 0 43px;
  background-color: #fff;
  box-shadow: $box-shadow;
  max-width: 943px;
  margin: 0 auto;

  .success_content {
    width: 100%;
    text-align: center;
    font-size: 20px;
    line-height: 1.5;
    color: $primary-purple;

    img {
      width: 114px;
      margin-bottom: 40px;
    }

    .main {
      margin-bottom: 40px;
      padding: 0 140px;

      p {
        line-height: 1.5;
      }

      a {
        color: #a2d4ff;
        text-decoration: underline;
        word-wrap: break-word;
      }
    }

    .el-button {
      min-width: 164px;
      border-radius: 23px;
      font-size: 14px;
      line-height: 1.5;
      font-weight: 600;
      padding: 12px 20px;
    }
  }
}

@media (max-width: 768px) {
  .success .success_content .main {
    margin: 0 auto 40px;
    padding: 0 24px;
  }
}

@media (max-width: 500px) {
  .success .success_content {
    font-size: 16px;

    img {
      width: 61px;
    }
  }
}
