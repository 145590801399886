.form_internationalAndAu {
  strong {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.42;
    color: $primary-purple;
  }

  .box {
    margin-top: 31px;

    .title {
      font-size: 24px;
      line-height: 23px;

      span {
        border-radius: 50%;
        background-color: $primary-green;
        padding: 5px 15px;
        color: white;
        margin-right: 10px;
      }
    }

    .form_content_box {
      margin-top: 8px;
      .info {
        margin-bottom: 40px;

        p {
          color: #00063b;
          line-height: 23px;
          margin-top: 20px;
        }
      }

      ul {
        display: grid;
        grid-template-columns: 48% 48%;
        column-gap: 4%;

        &.bank_info {
          border: $container-border;
          border-radius: $container-radius;

          padding: 40px;
          li {
            margin-bottom: 20px;
            span {
              display: block;
              font-size: 18px;

              &.title_name {
                font-weight: bold;
                line-height: 1.44;
                color: $primary-green;
                margin-bottom: 8px;
              }

              &.value_info {
                line-height: 1.44;
                color: $primary-purple;
              }
            }
          }
        }
      }

      .warn_info {
        line-height: 1.64;
        color: #ed164e;
        margin-top: 10px;
        padding: 10px 0;
        font-size: 16px;
      }

      .form_button {
        text-align: center;
      }
    }
  }
}

@media (max-width: 550px) {
  .form_internationalAndAu {
    padding: 38px 24px;

    strong {
      font-size: 20px !important;
    }

    .box {
      .title {
        font-size: 16px !important;
      }

      .form_content_box {
        .info {
          margin-bottom: 50px;

          p {
            margin-top: 0;
          }
        }

        ul {
          grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));

          &.bank_info {
            padding: 20px;
            li span {
              font-size: 16px;
            }
          }
        }

        .warn_info {
          @include rtl-sass-prop(padding-left, padding-right, 0);
        }
      }

      &:nth-child(2) {
        @include rtl-sass-prop(padding-left, padding-right, 0);
      }
    }
  }
}
